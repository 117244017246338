/* ContactList.css */

body {
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  form {
    margin-bottom: 2em;
  }
  
  input, button {
    margin: 0.5em 0;
    padding: 0.5em;
    font-size: 1em;
  }
  
  button {
    cursor: pointer;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  thead {
    background-color: #f5f5f5;
  }
  
  th, td {
    padding: 1em;
    border: 1px solid #ddd;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }